<template>
  <a-config-provider :locale="zh_CN">
    <div class="app">
      <keep-alive>
        <!--使用keep-alive会将页面缓存-->
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import Vue from "vue";
import utils from "@/utils/utils.js";
import MutationType from "@/store/mutation-type.js";
import axios from "axios";
import rem from "@/utils/rem.js";
import { Toast } from "vant";
import VConsole from "vconsole";
import NavBar from "@/components/nav/index/index.vue";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";

import "moment/locale/zh-cn";
moment.locale("zh-cn");

export default {
  data() {
    return {
      zh_CN,
      show: null,
      innerWidth: 0,
      innerHeight: 0,
      screenWidth: 0,
      devicePixelRatio: 0,
    };
  },
  components: {
    NavBar,
  },
  computed: {},
  async created() {
    await this.$nextTick();
    this.innerWidth = innerWidth;
    this.innerHeight = innerHeight;
    this.screenWidth = window.screen.availWidth;
    this.screenHeight = window.screen.availHeight;
    this.devicePixelRatio = window.devicePixelRatio;
  },
  watch: {
    $route(to, from) {},
  },
  async mounted() {
    // new VConsole();
    await this.$nextTick();
    this.show = this.$el;
    this.getSize();
    window.onresize = () => {
      this.getSize();
    };
  },
  methods: {
    getSize() {
      this.innerWidth = innerWidth;
      this.innerHeight = innerHeight;
      this.screenWidth = window.screen.availWidth;
      this.screenHeight = window.screen.availHeight;
      this.devicePixelRatio = window.devicePixelRatio;
      this.resizeWidth();
    },
    resizeWidth() {
      let a = this.innerWidth / (this.show.width || 720);
      let body = document.getElementsByTagName("body")[0];
      // this.show.style.transform = "scale(" + a + ")";
      body.style.transform = "scale(" + a + ")";
      body.style.width = "720px";
      body.style["transform-origin"] = "left top 0";
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/reset.scss";
@import "@/styles/common.scss";
</style>

<style lang="scss">
.app {
  position: relative;
  $font-size: 14px;
  color: rgba($color: #fff, $alpha: 0.65);
  font-size: 14px;
  width: 720px;
  height: 100%;
  transform-origin: left top 0;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  button,
  em,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  input,
  p,
  span,
  strong,
  textarea {
    font-size: $font-size;
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
  }
  *,
  :after,
  :before {
    box-sizing: border-box;
  }
}
</style>
<style lang="scss">
@import "@/styles/antcs.scss";
</style>
