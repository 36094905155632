import Vue from "vue";
import Vuex from "vuex";
import MutationType from "./mutation-type.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null, // 请求token
    userInfo: null, //getinfo 返回的用户信息
    cancelReqTokenArr: [], // 取消请求token数组
    region: null,
  },
  getters: {
    token: (state) => {
      return state.token;
    },
    userInfo: (state) => {
      let userInfo = state.userInfo;
      if (!userInfo) {
        userInfo = localStorage.getItem("userInfo");
      }
      return JSON.parse(userInfo);
    },
    region: (state) => {
      let region = state.region;
      if (!region) {
        region = localStorage.getItem("region");
      }
      return region;
    },
  },
  mutations: {
    setUserInfo(state, data) {
      if (data == "null") {
        return;
      }
      state.userInfo = data;
      localStorage.setItem("userInfo", data);
    },
    setRegion(state, data) {
      if (data == "null") {
        return;
      }
      state.region = data;
      localStorage.setItem("region", data);
    },
    //添加取消请求token
    [MutationType.PUSH_REQ_TOKEN](state, payload) {
      state.cancelReqTokenArr.push(payload.cancelToken);
    },
    //取消请求
    [MutationType.CLEAR_REQ_TOKEN](state) {
      let { cancelReqTokenArr } = state;
      cancelReqTokenArr.forEach((item) => {
        item("router cancel request");
      });
      state.cancelReqTokenArr = [];
    },
    //设置token
    [MutationType.SET_TOKEN_MUTATION](state, data) {
      state.token = data;
    },
  },
  actions: {},
  modules: {},
});
