import Vue from "vue";
import VueRouter from "vue-router";
import { Toast } from "vant";
//导入
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "@/store";
import storage from "@/storage";
import MutationType from "@/store/mutation-type.js";
import utils from "@/utils/utils.js";
Vue.use(VueRouter);

//解决编程式路由往同一地址跳转时会报错的情况 貌似不加这个有时候会引起页面卡住
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    // 主布局
    path: "/",
    redirect: { name: "Login" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index/index.vue"),
    meta: {
      title: "login",
      // keepAlive: false,
    },
  },
  {
    // preloading
    path: "/home",
    name: "Home",
    component: () => import("@/views/home/index/index.vue"),
    meta: {
      title: "home",
      // keepAlive: false,
    },
  },
  {
    // 主布局
    path: "/datav",
    name: "Datav",
    component: () => import("@/views/home/index/index.vue"),
    meta: {
      title: "项目概览",
      // keepAlive: false,
    },
    children: [
      {
        // 数据中心
        path: "/",
        name: "Overlay",
        component: () => import(`@/views/overlay/index/index.vue`),
        meta: {
          title: "数据中心",
          // keepAlive: false,
        },
      },

      {
        // 信用管理
        path: "labour",
        name: "Labour",
        component: () => import("@/views/labour/index/index.vue"),
        meta: {
          title: "信用管理",
          // keepAlive: false,
        },
      },
      {
        // 监管网格
        path: "grid",
        name: "Grid",
        component: () => import("@/views/grid/index/index.vue"),
        meta: {
          title: "监管网格",
          // keepAlive: false,
        },
      },
      {
        // 基地巡查
        path: "base",
        name: "Base",
        component: () => import("@/views/base/index/index.vue"),
        meta: {
          title: "基地巡查",
        },
      },
      {
        // 检验检测
        path: "inspect",
        name: "Inspect",
        component: () => import("@/views/inspect/index/index.vue"),
        meta: {
          title: "检验检测",
        },
      },
      {
        // 合格证
        path: "certificate",
        name: "Certificate",
        component: () => import("@/views/certificate/index/index.vue"),
        meta: {
          title: "合格证",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  // base: "/bagcloud/",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  Toast.clear();
  NProgress.start();
  // store.commit(MutationType.CLEAR_REQ_TOKEN); // 取消请求
  let token = store.state.token;
  let userInfo = store.state.userInfo;
  if (to.name == "login") {
    next();
  }
  if (!token) {
    token = storage.getToken();
    userInfo = localStorage.getItem("userInfo");
    store.commit(MutationType.SET_TOKEN_MUTATION, token);
    store.commit("setUserInfo", userInfo);
    next();
  }
  next();
});
router.afterEach((to, from) => {
  NProgress.done();
});
export default router;
